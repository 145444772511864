@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
}

body {
    font-size: 1.6rem;
    font-family: "Inter", sans-serif;
}

a {
    color: inherit;
    text-decoration: none;
}

button {
    border: none;
    background: none;
    cursor: pointer;
}

ul {
    list-style-position: inside;
}

input {
    min-width: 0;
}

input:focus,
textarea:focus {
    outline: none;
}

/* table,
td,
th {
    border: 2px solid black;
    border-collapse: collapse;
} */

.css-1pahdxg-control {
    border-color: #55bb53 !important;
    box-shadow: 0 0 0 1px #55bb53 !important;
}

.calendly-overlay .calendly-popup {
    max-height: 70rem !important;
}

.valid,
.invalid {
    margin: 0 !important;
    align-items: center !important;
    gap: 0.5rem;
}

.valid span,
.invalid span {
    padding: 0 !important;
}

.pagination {
    margin-top: 4rem;
    justify-content: center;
    display: flex;
    padding-left: 0;
    list-style: none;
}

.page-item .page-link {
    position: relative;
    display: block;
    margin: 0 5px;
    min-height: 40px;
    min-width: 40px;
    border-radius: 20px;
    text-align: center;
    line-height: 40px;
    color: #55bb53;
    text-decoration: none;
    transition: 0.3s all;
}

.page-item .page-link:hover {
    background: #55bb53;
    color: #fff;
    text-decoration: none;
}

.page-item.active .page-link {
    font-weight: 700;
    color: #ffffff;
    background-color: #55bb53;
}

.page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    cursor: auto;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}
