@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translate3d(0, -1rem, 0);
  }
}

.loadingFont {
  font-size: 1.6rem;
  font-weight: 600;
}

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  .loader {
    display: flex;
    justify-content: center;
    div {
      animation: bouncing-loader 0.6s infinite alternate;
      background: #55bb53;
      border-radius: 50%;
      height: 1rem;
      margin: 3rem 0.2rem;
      width: 1rem;
      &:nth-child(2) {
        animation-delay: 0.2s;
      }

      &:nth-child(3) {
        animation-delay: 0.2s;
      }
    }
  }
}
